// open and close create panel
const createButton = document.getElementById("create-source")
const createPanel = document.getElementById("create-panel")
const closeAddPanel = document.getElementById("close-add-panel");

createButton.addEventListener('click', function(){
    createPanel.style.transform = "translateY(0%)"
    createPanel.style.opacity = "1"
})

closeAddPanel.addEventListener('click', function(){
    createPanel.style.transform = "translateY(-100%)"
    createPanel.style.opacity = "0"
})


// add row in the panel

const add = document.getElementById("add-row");
const row = document.getElementById("container");
const select = document.getElementById("select");
const text = document.getElementById("text")

add.addEventListener('click', function(){

    newSelect = select.cloneNode(true);
    newText = text.cloneNode();

    newSelect.name = "mediums[" + (parseInt(row.childElementCount)) + "][channel]";
    newSelect.value = "";
    newText.name = "mediums[" + (parseInt(row.childElementCount)) + "][medium]";
    newText.value = "";

    let insertRow = document.createElement("div")
    insertRow.className = "row-input mb-3"
    insertRow.insertAdjacentElement("beforeend", newSelect)
    insertRow.insertAdjacentElement("beforeend", newText)

    row.insertAdjacentElement("beforeend", insertRow)
})
