require("jquery")

$.ajax({
    url: '/api/generator',
    type: "GET",
    dataType: "json",
    success: function (data) {
        getData(data);
    },
    error: function (error) {
        console.log("Error", error);
    }
});

//data
var json = []

// Inputs
const inputChannels = document.getElementById("channel")
const tabBody = document.getElementById("tab-body")
const tableau = document.getElementById("tab")

//Getter
function getData(data){
    json = data
    if(inputChannels.value){
        getTable()
    }
}

inputChannels.addEventListener("change", () => {
    getTable()
})

function getTable(){

    tableau.style.opacity = "1"
    tableau.style.position = "relative"
    tableau.style.left = "0"

    if(inputChannels.value === ""){
        console.log("aucun champs saisie")
    }
    else{
        let channels = json.find(element => element.name === inputChannels.value)

        while (tabBody.firstChild) {
            tabBody.removeChild(tabBody.firstChild);
        }

        channels.sources.forEach( (source) => {

            // Create element
            let row = document.createElement('tr')
            let tdSource = document.createElement('td')
            let tdMedium = document.createElement('td')

            tdSource.innerHTML = source.name

            source.mediums.forEach( (medium) => {

                let container = document.createElement('div')
                container.classList.add("form-check")

                let label = document.createElement('label')
                label.classList.add("form-check-label")
                label.setAttribute("for", source.name + "-" + medium)
                label.innerHTML = medium

                let checkbox = document.createElement('input')
                checkbox.classList.add("form-check-input")
                checkbox.setAttribute("id", source.name + "-" + medium)
                checkbox.type = "checkbox"
                checkbox.value = medium
                checkbox.name = "medium[" + source.name + "][" + medium + "]"

                if(restoreMediums){
                    for (const [key, value] of Object.entries(restoreMediums)) {
                        if(key === source.name){
                            var mediums = value
                            for (const [key, value] of Object.entries(mediums)) {
                                if(key === medium){
                                    checkbox.checked = true
                                }
                            }
                        }
                    }
                }

                container.insertAdjacentElement("beforeend", checkbox)
                container.insertAdjacentElement("beforeend", label)

                tdMedium.insertAdjacentElement("beforeend", container)
            })

            row.classList.add('table-light')
            row.insertAdjacentElement("beforeend", tdSource)
            row.insertAdjacentElement("beforeend", tdMedium)

            tabBody.insertAdjacentElement("beforeend", row)
        })
    }
}


// copy text
var btnLink = document.getElementsByClassName('btn-copy-link')
var btnUTM = document.getElementsByClassName('btn-copy-utm')
var alert = document.getElementById('alert')

for(let i = 0; i < btnLink.length; i++){
    btnLink[i].addEventListener('click', () => {
        let link = document.getElementById('copy-link-' + btnLink[i].name)
        link.select()
        btnLink[i].childNodes[3].innerHTML = "Lien copié"
        btnLink[i].classList.add('btn-success')
        document.execCommand('copy')

        btnUTM[i].childNodes[3].innerHTML = "Copier l'utm"
        btnUTM[i].classList.remove('btn-success')

        alert.style.display = "block"
        alert.innerHTML = "Le lien a été copié"

        setTimeout(() =>{
            alert.style.display = "none"
        }, 2000)
    })
}

for(let i = 0; i < btnUTM.length; i++){
    btnUTM[i].addEventListener('click', () => {
        let utm = document.getElementById('copy-utm-' + btnUTM[i].name)
        utm.select()
        btnUTM[i].childNodes[3].innerHTML = "UTM copié"
        btnUTM[i].classList.add('btn-success')
        document.execCommand('copy')

        btnLink[i].childNodes[3].innerHTML = "Copier le lien"
        btnLink[i].classList.remove('btn-success')

        alert.style.display = "block"
        alert.innerHTML = "L'utm a été copié"

        setTimeout(() =>{
            alert.style.display = "none"
        }, 2000)
    })
}

// Vérification
let url = document.getElementById("url")
let urlError = document.getElementById("url-error")

let campagne = document.getElementById("campagne")
let campagneError = document.getElementById("campagne-error")

let event = document.getElementById("event")
let eventError = document.getElementById("event-error")

let porte = document.getElementById("porte")
let porteError = document.getElementById("porte-error")

let channel = document.getElementById("channel")
let channelError = document.getElementById("channel-error")

url.addEventListener("focusout", () => {
    if(url.value){
        if(url.value.includes("https://") || url.value.includes("http://")){
            if(url.value.includes("utm_source") || url.value.includes("utm_medium") || url.value.includes("utm_campaign")){
                url.classList.add("is-invalid")
                urlError.classList.add("invalid-feedback")
                urlError.innerHTML = "Le lien comporte déjà des trackers"
            }
            else if(url.value.includes("@") || url.value.includes("\"") || url.value.includes("\'")
                || url.value.includes("\(") || url.value.includes("\)") || url.value.includes("\!") || url.value.includes("€")
                || url.value.includes("$") || url.value.includes("\*") || url.value.includes("é") || url.value.includes("à")
                || url.value.includes("%") || url.value.includes(",") || url.value.includes("\`") || url.value.includes("ù")
                || url.value.includes("<") || url.value.includes(">") || url.value.includes("è") || url.value.includes("£")
                || url.value.includes("|") || url.value.includes("^") || url.value.includes("}") || url.value.includes("{")
                || url.value.includes(" ")
            ){
                url.classList.add("is-invalid")
                urlError.classList.add("invalid-feedback")
                urlError.innerHTML = "Seuls les caractères alphanumériques et “-” sont autorisés"
            }
            else if((url.value.match(/https/g) || []).length > 1){
                url.classList.add("is-invalid")
                urlError.classList.add("invalid-feedback")
                urlError.innerHTML = "Vous ne pouvez pas entrez deux liens dans ce champs"
            }
            else if(url.value.charAt(url.value.length-1) === "?"){
                url.classList.add("is-invalid")
                urlError.classList.add("invalid-feedback")
                urlError.innerHTML = "Le lien possède un paramètre mal orthographié"
            }
            else{
                url.classList.remove("is-invalid")
                url.classList.add("is-valid")

                urlError.classList.remove("invalid-feedback")
                urlError.innerHTML = ""
            }
        }
        else{
            url.classList.add("is-invalid")
            urlError.classList.add("invalid-feedback")
            urlError.innerHTML = "Veuillez entrer une URL débutant par https:// ou http:// (déconseillé)"
        }
    }
    else{
        url.classList.add("is-invalid")
        urlError.classList.add("invalid-feedback")
        urlError.innerHTML = "Le champs est requis."
    }
})

campagne.addEventListener("change", () => {
    if(campagne.value){
        campagne.classList.remove("is-invalid")
        campagne.classList.add("is-valid")

        campagneError.classList.remove("invalid-feedback")
        campagneError.innerHTML = ""
    }
})

event.addEventListener("change", () => {
    if(event.value){
        event.classList.remove("is-invalid")
        event.classList.add("is-valid")

        eventError.classList.remove("invalid-feedback")
        eventError.innerHTML = ""
    }
})

porte.addEventListener("focusout", () => {
    if(porte.value){
        if(porte.value.includes("@") || porte.value.includes("&") || porte.value.includes("\"") || porte.value.includes("\'")
            || porte.value.includes("\(") || porte.value.includes("\)") || porte.value.includes("\!") || porte.value.includes("€")
            || porte.value.includes("$") || porte.value.includes("\*") || porte.value.includes("é") || porte.value.includes("à")
            || porte.value.includes("%") || porte.value.includes(",") || porte.value.includes("\`") || porte.value.includes("ù")
            || porte.value.includes("<") || porte.value.includes(">") || porte.value.includes("è") || porte.value.includes("£")
            || porte.value.includes("|") || porte.value.includes("^") || porte.value.includes("}") || porte.value.includes("{")
            || porte.value.includes(" ")
        ){
            porte.classList.add("is-invalid")
            porteError.innerHTML = "Seuls les caractères alphanumériques et “-” sont autorisés"
        }
        else{
            porte.classList.remove("is-invalid")
            porte.classList.add("is-valid")

            porteError.classList.remove("invalid-feedback")
            porteError.innerHTML = ""
        }
    }
    else{
        porte.classList.add("is-invalid")
        porteError.innerHTML = "Le champs est requis."
    }
})

channel.addEventListener("change", () => {
    if(channel.value){
        channel.classList.remove("is-invalid")
        channel.classList.add("is-valid")

        channelError.innerHTML = ""
    }
})

// Has been created
let checkboxes = document.getElementsByClassName("checked")
let status = document.getElementsByClassName("status")

for(let i = 0; i < checkboxes.length; i++){
    let card = document.getElementById("card-" + checkboxes[i].value)

    card.addEventListener('click', (e) => {
        let element = e.target.tagName
        if(element.toString() === "DIV" || element.toString() === "H4"){
            if(checkboxes[i].checked){
                checkboxes[i].checked = false
                status[i].innerHTML = "(Non traité)"
                status[i].style.color = "red"
                card.style.borderColor = "transparent"
            }
            else{
                checkboxes[i].checked = true
                status[i].innerHTML = "(Traité)"
                status[i].style.color = "green"
                card.style.border = "1px solid #38c172"
            }
        }
    })

    checkboxes[i].addEventListener("change", () => {
        if(checkboxes[i].checked){
            status[i].innerHTML = "(Traité)"
            status[i].style.color = "green"
            card.style.border = "1px solid #38c172"
        }
        else{
            card.style.borderColor = "transparent"
            status[i].innerHTML = "(Non traité)"
            status[i].style.color = "red"
        }
    })

}

let page = document.getElementById("all-links")

setTimeout(() => {
    page.scrollIntoView({behavior: 'smooth'})
}, 800)














