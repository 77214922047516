// modify panel
const modifyButton = document.getElementsByClassName("modify-button");
const panelBackground = document.getElementById("panel-background");
const panel = document.getElementById("modify-panel");
const inputName = document.getElementById("input-name");
const closePanel = document.getElementById("close-panel");

for(let i = 0; i < modifyButton.length; i++){
    modifyButton[i].addEventListener("click", function (){
        inputName.value = modifyButton[i].name
        panelBackground.style.transform = "translateY(0%)"

        setTimeout(function (){
            panelBackground.style.opacity = "1"
            panel.style.top = (window.innerHeight / 2) - 150 + "px"
            panel.style.opacity = "1"
        }, 200)
    })
}

closePanel.addEventListener('click', function (){
    panelBackground.style.opacity = "0"
    panel.style.top = "-400px"
    panel.style.opacity = "1"
    panelBackground.style.transform = "translateY(-100%)"
})

panelBackground.addEventListener("click", function() {
    // This is a click outside.
    panelBackground.style.opacity = "0"
    panel.style.top = "-400px"
    panel.style.opacity = "1"
    panelBackground.style.transform = "translateY(-100%)"
});
