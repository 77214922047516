// delete panel
const buttonDelete = document.getElementsByClassName("delete")
const backgroundDelete = document.getElementById("delete-background")
const deletePanel = document.getElementById("delete-panel")
const closeDellPanel = document.getElementById("close-dell-panel")
const dellForm = document.getElementById("dell-form")

closeDellPanel.addEventListener("click", function (){
    //background
    backgroundDelete.style.transform = "translateY(-100%)"
    backgroundDelete.style.opacity = "0"

    //panel
    deletePanel.style.top = "-400px"
    deletePanel.style.opacity = "0"
})

backgroundDelete.addEventListener("click", function() {
    // This is a click outside.
    backgroundDelete.style.transform = "translateY(-100%)"
    backgroundDelete.style.opacity = "0"

    //panel
    deletePanel.style.opacity = "0"
    deletePanel.style.top = "-400px"
});


for(let i = 0; i < buttonDelete.length; i++){
    buttonDelete[i].addEventListener('click', function (){

        dellForm.action = buttonDelete[i].name

        //background
        backgroundDelete.style.transform = "translateY(0%)"
        backgroundDelete.style.opacity = "1"

        //panel
        deletePanel.style.top = "350px"
        deletePanel.style.opacity = "1"
    })
}
