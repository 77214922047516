

if(window.location.pathname === '/'){
    require('./bootstrap');
    require('./form');
}
else if(window.location.pathname === '/sources'){
    require('./bootstrap');
    require('./deletePanel');
    require('./createPanel');
}
else if(window.location.pathname === '/mediums'){
    require('./bootstrap');
    require('./deletePanel');
    require('./modifyPanel');
}
else if(window.location.pathname === '/channels'){
    require('./bootstrap');
    require('./deletePanel');
    require('./modifyPanel');
}
else{
    require('./bootstrap');
    require('./deletePanel');
    require('./modifyPanel');
    require('./createPanel');
}




